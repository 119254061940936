import { Button, Checkbox, DatePicker, Flex, Pagination, Select, Switch} from "antd";
import React, { useState } from "react";
import { useGetAllImagesPreviewQuery, useGetImagesPreviewQuery } from "../../../store/webchatApiSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import "./AdminHistoryImageGenerations.scss"
import { Loader } from "../../../Components/common/Loader";
import { ChatImageCard } from "../../../Components/ChatImageCard/ChatImageCard";
import { useNavigate } from "react-router-dom";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { Empty } from "../../../Components/common/Empty";
import UserSelector from "../../../Components/common/UserSelector";

const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean
}

export const AdminHistoryImageGenerations: React.FC<PropsType> = ({showFull}) => {
  const navigate = useNavigate();
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [searchUser, setSearchUser] = useState<any>();

  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [ pagination, setPagination] = useState({page: 1, limit: showFull?24:12})
  const { data, isLoading, isError  } = useGetAllImagesPreviewQuery({
    page: pagination.page,
    limit: pagination.limit,
    from: (range ? range[0]?.toDate().getTime() : undefined),
    to: (range ? range[1]?.toDate().getTime() : undefined),
    user_id: (searchUser ? searchUser : undefined),
    neuronet_id: (neronet ? neronet.split('_')[0] : undefined),
    option_id: (neronet && neronet.split('_').length===2)?neronet.split('_')[1]:undefined,

  });
  const setDateRange = (e: any) => {
    setRange(e);
  };


  if (isLoading) return <Loader />
  if(isError) return <>error</>
  const [messages, count] = data?data:[[],0]
  
  return (
    <div className={'adminHistoryImageGenerations '+currentAlgorithm} style={{ }}>
      <div className="historyImageGenerationsHeader">
          <span>Генерация изображений</span>
          <span>
            {!showFull &&   <Button disabled={!isLoading && data && data[1]===0} onClick={()=>navigate('images')}>Смотреть все генерации</Button>}
          </span>
      </div>
      {showFull && (
        <div className={"historyImageGenerationsFilters "+currentAlgorithm}>
          <div className="historyImageGenerationsFiltersSelector">
            <span>Задача</span>
            <span>
              <NeuronetsSelector
                value={neronet}
                type="image"
                onChange={(value) => {
                  setNeronet(value);
                }}
              />
            </span>
          </div>
          <div className="historyImageGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div className="historyImageGenerationsFiltersSelector">
          <span>
              Пользователи
              </span>
              <span>
            <UserSelector value={searchUser} onChange={setSearchUser} />
              </span>
          </div>
          <div className="historyImageGenerationsFiltersSelector">
          {/* <span>
              Статус
              </span>
              <span>
            <Select style={{width: '240px'}}>
              <Select.Option key={'success'} value='success'>Успешные генерации</Select.Option>
              <Select.Option key={'all'} value='all'>Все генерации</Select.Option>
            </Select>
              </span> */}
          </div>
        </div>
      )}
      { !isLoading && data && data[1]>0 &&
        <>
        <Flex className='chatMessageImageContainer'>
          {messages &&
            messages.map((item: any) => {
              return <ChatImageCard mode="admin" key={item.id} item={item} />
          })}
        </Flex>
        <Pagination onChange={(page, limit)=>{
          console.log(page, limit)
          setPagination({...pagination,page, limit})
        }} align="end" defaultCurrent={pagination.page} pageSize={pagination.limit} total={count} />
        </>
    }
    { !isLoading && data && data[1]===0 &&
      <Empty />
    }
  </div>
  );
};
